import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, exhaustMap, map, mergeMap } from 'rxjs/operators';

import {
  createSeriesFailedAction,
  deleteSeriesFailedAction,
  fetchAllSeriesAction,
  fetchAllSeriesFailedAction,
  seriesActionTypes,
  updateSeriesFailedAction,
} from './series.actions';
import { SeriesService } from './series.service';

@Injectable()
export class SeriesEffects {
  fetchAllSeries$ = createEffect(() => this.actions$.pipe(
    ofType(fetchAllSeriesAction),
    exhaustMap(() => this.seriesService.fetchAll()
      .pipe(
        map((series) => ({
          type: seriesActionTypes.SERIES_RECEIVED,
          series,
        })),
        catchError(() => [fetchAllSeriesFailedAction()])
      ))
    )
  );

  createSeries$ = createEffect(() =>
    this.actions$.pipe(
      ofType(seriesActionTypes.CREATE_SERIES),
      exhaustMap((action) =>
        this.seriesService.createOne(action['series']).pipe(
          map((series) => {
            const serie = series[0];
            return { type: seriesActionTypes.SERIES_CREATED, serie };
          }),
          catchError(() => [createSeriesFailedAction()])
        )
      )
    )
  );

  deleteSeries$ = createEffect(() =>
    this.actions$.pipe(
      ofType(seriesActionTypes.DELETE_SERIES),
      mergeMap((action) =>
        this.seriesService.deleteOne(action['id']).pipe(
          map(() => ({
            type: seriesActionTypes.SERIES_DELETED,
            id: action['id'],
          })),
          catchError(() => [deleteSeriesFailedAction()])
        )
      )
    )
  );

  updateSeries$ = createEffect(() =>
    this.actions$.pipe(
      ofType(seriesActionTypes.UPDATE_SERIES),
      mergeMap((action) =>
        this.seriesService.updateOne(action['id'], action['series']).pipe(
          map(seriesArray => ({
            type: seriesActionTypes.SERIES_UPDATED,
            id: action['id'],
            series: seriesArray[0]
          })),
          catchError(() => [updateSeriesFailedAction({ "id": action['id']})])
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private seriesService: SeriesService
  ) {}
}
