import { Action, createReducer, on } from '@ngrx/store';

import { arrayToHashMap } from '../store.utils';
import {
  fetchAllTechniquesAction,
  fetchAllTechniquesFailedAction,
  techniqueCreatedAction,
  techniqueDeletedAction,
  techniquesReceivedAction,
} from './technique.actions';
import { Technique } from './technique.model';

export interface TechniquesState {
  entities: { [id: number]: Technique };
  initialized: boolean;
  isLoading: boolean;
  hasError: boolean;
}

export const initialTechniqueState: TechniquesState = {
  entities: {},
  initialized: false,
  isLoading: false,
  hasError: false,
};

const techniquesReducer = createReducer(
  initialTechniqueState,
  on(fetchAllTechniquesAction, (state) => ({
    ...state,
    isLoading: true,
    hasError: false,
  })),
  on(fetchAllTechniquesFailedAction, (state) => ({
    ...state,
    isLoading: false,
    hasError: true,
  })),
  on(techniquesReceivedAction, (state, { techniques }) => ({
    ...state,
    initialized: true,
    isLoading: false,
    entities: arrayToHashMap(techniques),
  })),
  on(techniqueDeletedAction, (state, { id }) => {
    let newEntities = Object.assign({}, state.entities);
    delete newEntities[id];
    return { ...state, entities: newEntities };
  }),
  on(techniqueCreatedAction, (state, { technique }) => {
    return { ...state, entities: { ...state.entities, [technique.id]: technique } };
  })
);

export function reducer(state: TechniquesState | undefined, action: Action) {
  return techniquesReducer(state, action);
}
