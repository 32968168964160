import { Action, createReducer, on } from '@ngrx/store';

import { arrayToHashMap } from '../store.utils';
import {
  createSeriesAction,
  deleteSeriesAction,
  deleteSeriesFailedAction,
  fetchAllSeriesAction,
  fetchAllSeriesFailedAction,
  seriesCreatedAction,
  seriesDeletedAction,
  seriesReceivedAction,
  seriesUpdatedAction,
  updateSeriesAction,
} from './series.actions';
import { Series } from './series.model';

export interface SeriesState {
  entities: { [id: number]: Series };
  initialized: boolean;
  isLoading: boolean;
  isDeleting: boolean;
  hasError: boolean;
}

export const initialSeriesState: SeriesState = {
  entities: {},
  initialized: false,
  isLoading: false,
  isDeleting: false,
  hasError: false,
};

const seriesReducer = createReducer(
  initialSeriesState,
  on(fetchAllSeriesAction, (state) => ({
    ...state,
    isLoading: true,
    hasError: false,
  })),
  on(fetchAllSeriesFailedAction, (state) => ({
    ...state,
    isLoading: false,
    hasError: true,
  })),
  on(createSeriesAction, (state) => ({
    ...state,
    isLoading: true,
    hasError: false,
  })),
  on(seriesReceivedAction, (state, { series }) => ({
    ...state,
    initialized: true,
    isLoading: false,
    entities: arrayToHashMap(series),
  })),
  on(deleteSeriesAction, (state) => ({
    ...state,
    isDeleting: true,
    hasError: false,
  })),
  on(deleteSeriesFailedAction, (state) => ({
    ...state,
    isDeleting: false,
    hasError: true,
  })),
  on(seriesDeletedAction, (state, { id }) => {
    let newEntities = Object.assign({}, state.entities);
    delete newEntities[id];
    return { ...state, entities: newEntities, isDeleting: false };
  }),
  on(seriesCreatedAction, (state, { serie }) => {
    return { ...state, isLoading: false, entities: { ...state.entities, [serie.id]: serie } };
  }),
  on(updateSeriesAction, (state) => ({
    ...state,
    isLoading: true,
    hasError: false,
  })),
  on(seriesUpdatedAction, (state, { id, series }) => {
    return { ...state, isLoading: false, entities: { ...state.entities, [id]: series } };
  })
);

export function reducer(state: SeriesState | undefined, action: Action) {
  return seriesReducer(state, action);
}
