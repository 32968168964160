import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from '../../../../environments/environment';
import { Series } from './series.model';

@Injectable({
  providedIn: 'root',
})
export class SeriesService {
  constructor(private http: HttpClient) {}

  fetchAll(): Observable<Series[]> {
    return this.http.get<Series[]>(`${environment.apiUrl}/series`);
  }

  createOne(series: Series): Observable<Series[]> {
    return this.http.post<Series[]>(`${environment.apiUrl}/series`, series);
  }

  updateOne(id: number, series: Series): Observable<Series[]> {
    return this.http.post<Series[]>(`${environment.apiUrl}/series/${id}`, series);
  }

  deleteOne(id: number): Observable<void> {
    return this.http.delete<void>(`${environment.apiUrl}/series/${id}`);
  }
}
