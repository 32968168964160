import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { forkJoin, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

import { Artwork, Picture } from './artwork.model';

@Injectable({
  providedIn: 'root',
})
export class ArtworkService {
  constructor(private http: HttpClient) {}

  fetchArtworkBySeries(seriesId: number): Observable<Artwork[]> {
    return this.http.get<Artwork[]>(`${environment.apiUrl}/artwork?seriesId=${seriesId}`);
  }

  fetchAllArtwork(): Observable<Artwork[]> {
    return this.http.get<Artwork[]>(`${environment.apiUrl}/artwork`);
  }

  upsert(artwork: Artwork[]): Observable<Artwork[]> {
    return this.http.post<Artwork[]>(`${environment.apiUrl}/artwork`, artwork);
  }

  deleteOne(id: number, deleteImages = false): Observable<void> {
    let url = `${environment.apiUrl}/artwork/${id}`;
    if (deleteImages) {
      url = `${url}?deleteImages=true`
    }
    return this.http.delete<void>(url);
  }

  uploadImages(files: File[], slug: string): Observable<Picture[]> {
    const calls: Observable<Picture>[] = [];
    for (let index = 0; index < files.length; index++) {
      const file = files[index];
      const payload = new FormData();
      payload.append('image', file);
      const call = this.http.post<Picture>(
        `${environment.apiUrl}/images/upload/${slug}`,
        payload
      );
      calls.push(call);
    }

    return forkJoin(calls);
  }

  deleteImages(picture: Picture): Observable<Picture> {
    let url = `${environment.apiUrl}/images/delete`;
    return this.http.post<Picture>(
      url,
      picture
    );
  }
}
