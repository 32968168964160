import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { ForbiddenModule } from './forbidden/forbidden.module';
import { LoggedOutModule } from './logged-out/logged-out.module';
import { NotFoundModule } from './not-found/not-found.module';
import { CoreStoreModule } from './store/store.module';

@NgModule({
  imports: [
    CommonModule,
    LoggedOutModule,
    NotFoundModule,
    ForbiddenModule,
    CoreStoreModule,
  ],
  exports: [LoggedOutModule, NotFoundModule, ForbiddenModule],
})
export class CoreModule {}
