import { ArtworkEffects } from './artwork/artwork.effects';
import { ArtworkState } from './artwork/artwork.reducer';
import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import * as fromSeries from './series/series.reducer';
import * as fromArwork from './artwork/artwork.reducer';
import * as fromTechniques from './technique/technique.reducer';
import { SeriesEffects } from './series/series.effect';
import { SeriesState } from './series/series.reducer';
import { TechniquesState } from './technique/technique.reducer';
import { TechniquesEffect } from './technique/technique.effect';

export interface AppState {
  series: SeriesState;
  artwork: ArtworkState;
  techniques: TechniquesState;
}

@NgModule({
  imports: [
    StoreModule.forRoot({
      series: fromSeries.reducer,
      artwork: fromArwork.reducer,
      techniques: fromTechniques.reducer,
    }),
    EffectsModule.forRoot([SeriesEffects, ArtworkEffects, TechniquesEffect]),
  ],
})
export class CoreStoreModule {}
