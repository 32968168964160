import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  { path: '', redirectTo: '/artwork', pathMatch: 'full' },
  {
    path: 'artwork',
    loadChildren: () =>
      import('./artwork/artwork.module').then((m) => m.ArtworkModule),
  },
  {
    path: 'bio',
    loadChildren: () =>
      import('./biography/biography.module').then((m) => m.BiographyModule),
  },
  {
    path: 'admin',
    loadChildren: () =>
      import('./admin/admin.module').then((m) => m.AdminModule),
  },
  { path: '**', redirectTo: '/artwork', pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: "enabled" })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
