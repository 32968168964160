import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from '../../../../environments/environment';
import { Technique } from './technique.model';

@Injectable({
  providedIn: 'root',
})
export class TechniqueService {
  constructor(private http: HttpClient) {}

  fetchAll(): Observable<Technique[]> {
    return this.http.get<Technique[]>(`${environment.apiUrl}/techniques`);
  }

  createOne(technique: Technique): Observable<Technique> {
    return this.http.post<Technique>(`${environment.apiUrl}/techniques`, technique);
  }

  deleteOne(id: number): Observable<void> {
    return this.http.delete<void>(`${environment.apiUrl}/techniques/${id}`);
  }
}
