import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, exhaustMap, map, mergeMap } from 'rxjs/operators';

import {
  createTechniqueFailedAction,
  deleteTechniqueFailedAction,
  fetchAllTechniquesFailedAction,
  techniqueActionTypes,
} from './technique.actions';
import { TechniqueService } from './technique.service';

@Injectable()
export class TechniquesEffect {
  fetchAllTechniques$ = createEffect(() =>
    this.actions$.pipe(
      ofType(techniqueActionTypes.FETCH_ALL),
      mergeMap(() =>
        this.techniqueService.fetchAll().pipe(
          map((techniques) => ({
            type: techniqueActionTypes.RECEIVED,
            techniques,
          })),
          catchError(() => [fetchAllTechniquesFailedAction()])
        )
      )
    )
  );

  createTechnique$ = createEffect(() =>
    this.actions$.pipe(
      ofType(techniqueActionTypes.CREATE),
      exhaustMap((action) =>
        this.techniqueService.createOne(action['technique']).pipe(
          map((techniques) => {
            const technique = techniques[0];
            return { type: techniqueActionTypes.CREATED, technique };
          }),
          catchError(() => [createTechniqueFailedAction()])
        )
      )
    )
  );

  deleteTechnique$ = createEffect(() =>
    this.actions$.pipe(
      ofType(techniqueActionTypes.DELETE),
      mergeMap((action) =>
        this.techniqueService.deleteOne(action['id']).pipe(
          map(() => ({
            type: techniqueActionTypes.DELETED,
            id: action['id'],
          })),
          catchError(() => [deleteTechniqueFailedAction()])
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private techniqueService: TechniqueService
  ) {}
}
