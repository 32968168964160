import { NgOptimizedImage, registerLocaleData } from '@angular/common';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import englishLocale from '@angular/common/locales/en';
import frenchLocale from '@angular/common/locales/fr';
import { APP_INITIALIZER, NgModule, importProvidersFrom } from '@angular/core';
import { MatDateFnsModule } from '@angular/material-date-fns-adapter';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AuthHttpInterceptor, AuthModule } from '@auth0/auth0-angular';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { GALLERY_CONFIG, GalleryConfig, GalleryModule } from 'ng-gallery';
import { LIGHTBOX_CONFIG, LightboxConfig, LightboxModule } from 'ng-gallery/lightbox';
import { environment } from 'src/environments/environment';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ConfigService } from './core/config/config.service';
import { CoreModule } from './core/core.module';

registerLocaleData(frenchLocale);
registerLocaleData(englishLocale);

export function appInit(configService: ConfigService) {
  return () => configService.load();
}

@NgModule({ declarations: [AppComponent],
    bootstrap: [AppComponent], imports: [CoreModule,
        BrowserModule,
        AuthModule.forRoot({ ...environment.auth }),
        AppRoutingModule,
        BrowserAnimationsModule,
        MatDateFnsModule,
        StoreDevtoolsModule.instrument({
            maxAge: 25,
            logOnly: false,
            autoPause: true,
            features: {
                pause: false,
                lock: true,
                persist: true
            }
        }),
        NgOptimizedImage,
        MatIconModule,
        MatButtonModule], providers: [
        ConfigService,
        {
            provide: APP_INITIALIZER,
            useFactory: appInit,
            multi: true,
            deps: [ConfigService],
        },
        { provide: MAT_DATE_LOCALE, useValue: 'fr-FR' },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthHttpInterceptor,
            multi: true,
        },
        {
            provide: LIGHTBOX_CONFIG,
            useValue: {
                keyboardShortcuts: false,
                panelClass: 'fullscreen'
            } as LightboxConfig
        },
        importProvidersFrom(GalleryModule),
        {
            provide: GALLERY_CONFIG,
            useValue: {
                thumbs: false
            } as GalleryConfig
        },
        provideHttpClient(withInterceptorsFromDi())
    ] })
export class AppModule {}
