import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Event, NavigationEnd, NavigationStart, Router } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'christiandecalvairac.com';

  isAdminSection: boolean = false;

  previousRoute: string = "";

  private _onDestroy$: Subject<void> = new Subject();

  constructor(private router: Router) {}

  ngOnInit(): void {
      this.isAdminSection = this.router.url.startsWith('/admin');
      this.router.events.pipe(takeUntil(this._onDestroy$)).subscribe((event: Event) => {
        if (event instanceof NavigationStart) {
          this.previousRoute = this.router.url;
          this.isAdminSection = event.url.startsWith('/admin');
        }
        if (event instanceof NavigationEnd
            && (this.previousRoute.startsWith("/bio") && event.url.startsWith('/artwork'))) {          
              this.scrollToTop();
        }

        if (event instanceof NavigationEnd
          && (this.previousRoute.startsWith("/artwork") && event.url.startsWith('/bio'))) {          
          this.scrollToTop();
        }
    });
  }

  scrollToTop(): void {
    document.querySelector('div.artsy-page').scrollTo(0, 0);
  }
  

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }
}
