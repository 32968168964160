@import '../assets/scss/global/index.scss';

:host {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  position: relative;
  max-height: 100%;
  max-width: 100%;
}

.artsy-navigation {
  @include font-family-headings;
  font-weight: bold;
  color: color(gray);

  &__item {
    background: none;
    margin-right: space(large);
    border: none;
    padding: space(extra-small) 0;
    border-bottom: 1px solid #ffffff;
    color: color(gray);
    
    &:hover{
      border-bottom: 1px solid color(light-gray);
    }

    &:visited{
      text-decoration: none;
      color: color(gray);
    }

    &.selected {
      border-bottom: 1px solid color(light-gray);
    }
  }
}

.artsy-page {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  height: 100%;
  max-height: 100%;
  padding-right: auto;
  overflow-y: auto;

  &__header {
    display: flex;
    background-color: #ffffff;
    flex-direction: column;
    position: relative;
    top: 0;
    height: 8rem;
    min-height: 8rem;
    z-index: 1;
    padding: space(regular) space(huge) 0 space(huge);
    flex: 1 1 auto;
    max-width: 1200px;
    width: 100%;
    align-self: center;
    justify-content: space-between;

    img {
      margin-top: space(small);
    }

    &--admin {
      height: 7rem;
      min-height: 7rem;
    }

    button {
      color: color(gray) !important;
    }
  }

  &__content {
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    height: 100%;
    max-height: 100%;
    max-width: 1200px;
    width: 100%;
    align-self: center;
  }
}
