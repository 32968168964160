
<div class="artsy-page">
  <header class="artsy-page__header" [ngClass]="{'artsy-page__header--admin' : isAdminSection}">
    <img ngSrc="/assets/christian-v3.png" height="52" width="350" priority alt="Christian de Calvairac handwritten name" />
    <div class="artsy-navigation" *ngIf="!isAdminSection">
      <button class="artsy-navigation__item" routerLink="/artwork" routerLinkActive="selected">Oeuvres</button>
      <button class="artsy-navigation__item" routerLink="/bio" routerLinkActive="selected">Biographie</button>
    </div>
  </header>

  <main class="artsy-page__content">
    <router-outlet></router-outlet>
  </main>
</div>
