import { Action, createReducer, on } from '@ngrx/store';

import {
  allArtworkReceived,
  artworkUpsertedAction,
  artworkUpsertionFailedAction,
  deleteArtworkAction,
  deleteImagesAction,
  fetchAllArtworkAction,
  fetchingAllArtworkFailedAction,
  fetchingSeriesArtworkFailedAction,
  fetchSeriesArtworkAction,
  imageDeletionFailedAction,
  imageLoadedAction,
  imagesDeletedAction,
  imagesUploadedAction,
  imageUploadFailedAction,
  resetLoadedIMageCounterAction,
  seriesArtworkReceived,
  uploadImagesAction,
  upsertArtworkAction,
} from './artwork.actions';
import { Artwork, Picture } from './artwork.model';

export interface UploadState {
  inProgress: boolean;
  failed: boolean;
  files: Picture[];
  seriesId: number;
}

export interface ArtworkState {
  entities: Map<number, Artwork[]>;
  isLoading: boolean;
  hasError: boolean;
  upload: UploadState;
  imageLoadedCounter: number,
}

export const initialArtworkState: ArtworkState = {
  entities: new Map<number, Artwork[]>(),
  isLoading: false,
  hasError: false,
  upload: {
    inProgress: false,
    failed: false,
    files: null,
    seriesId: null
  },
  imageLoadedCounter: 0
};

const artworkReducer = createReducer(
  initialArtworkState,
  on(fetchSeriesArtworkAction, (state) => ({
    ...state,
    isLoading: true,
    hasError: false,
  })),
  on(fetchingSeriesArtworkFailedAction, (state) => ({
    ...state,
    isLoading: false,
    hasError: true,
  })),
  on(seriesArtworkReceived, (state, { pieces, seriesId }) => ({
    ...state,
    isLoading: false,
    entities: {
      ...state.entities,
      [seriesId]: pieces,
    },
  })),
  on(fetchAllArtworkAction, (state) => ({
    ...state,
    isLoading: true,
    hasError: false,
  })),
  on(fetchingAllArtworkFailedAction, (state) => ({
    ...state,
    isLoading: false,
    hasError: true,
  })),
  on(allArtworkReceived, (state, { pieces }) => {
    const pieceMap = pieces.reduce((map, piece) => {
      if (!map[piece.seriesId]) {
        map[piece.seriesId] = [piece];
      } else {
        map[piece.seriesId] = [...map[piece.seriesId], piece];
      }
      return map;
    }, {});
    
    return {
      ...state,
      entities: pieceMap as Map<number, Artwork[]>,
      upload: { ...initialArtworkState.upload }
    };
  }),
  on(deleteArtworkAction, (state, { id, seriesId }) => {
    const pieces: Artwork[] = state.entities[seriesId]
    const pieceMap = Object.assign({}, ...Array.prototype.concat
      .apply(state.entities[seriesId], pieces)
      .map(piece => ({[piece.id]: piece})));

    delete pieceMap[id];
    return {
      ...state,
      entities: {
        ...state.entities,
        [seriesId]: Object.values(pieceMap),
      },
      upload: { ...initialArtworkState.upload }
    };
  }),
  on(artworkUpsertedAction, (state, { pieces }) => {
    const pieceMap = Object.assign({}, ...Array.prototype.concat
      .apply(state.entities[pieces[0].seriesId], pieces)
      .map(piece => ({[piece.id]: piece})));
    return {
      ...state,
      entities: {
        ...state.entities,
        [pieces[0].seriesId]: Object.values(pieceMap),
      },
      upload: { ...initialArtworkState.upload }
    };
  }),
  on(uploadImagesAction, (state, { seriesId }) => ({
    ...state,
    upload: {
      ...state.upload,
      inProgress: true,
      failed: false,
      files: null,
      seriesId
    },
  })),
  on(imagesUploadedAction, (state, { pictures }) => {
    return ({
      ...state,
      upload: {
        ...state.upload,
        inProgress: false,
        files: [...pictures],
      },
  })}),
  on(imageUploadFailedAction, (state) => ({
    ...state,
    upload: {
      ...state.upload,
      inProgress: false,
      failed: true,
    },
  })),
  on(deleteImagesAction, (state) => ({
    ...state,
    upload: {
      ...state.upload,
      inProgress: true,
      failed: false,
    },
  })),
  on(imageDeletionFailedAction, (state) => ({
    ...state,
    upload: {
      ...state.upload,
      inProgress: false,
      failed: true,
    },
  })),
  on(imagesDeletedAction, (state) => ({
    ...state,
    upload: {
      ...state.upload,
      inProgress: false,
      files: null
    },
  })),
  on(imageLoadedAction, (state) => ({
    ...state,
    imageLoadedCounter: state.imageLoadedCounter + 1,
  })),
  on(resetLoadedIMageCounterAction, (state) => ({
    ...state,
    imageLoadedCounter: 0,
  })),
  on(upsertArtworkAction, (state) => ({
    ...state,
    isLoading: true,
    hasError: false,
  })),
  on(artworkUpsertionFailedAction, (state) => ({
    ...state,
    isLoading: false,
    hasError: true,
  })),
  on(artworkUpsertedAction, (state) => ({
    ...state,
    isLoading: false,
    hasError: false,
  })),
);

export function reducer(state: ArtworkState | undefined, action: Action) {
  return artworkReducer(state, action);
}
