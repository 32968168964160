import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';

import {
  artworkActionTypes,
  artworkDeletedAction,
  artworkDeletionFailedAction,
  artworkUpsertionFailedAction,
  fetchingAllArtworkFailedAction,
  fetchingSeriesArtworkFailedAction,
  imageDeletionFailedAction,
  imagesDeletedAction,
  imageUploadFailedAction,
} from './artwork.actions';
import { ArtworkService } from './artwork.service';

@Injectable()
export class ArtworkEffects {
  fetchSeriesArtwork$ = createEffect(() =>
    this.actions$.pipe(
      ofType(artworkActionTypes.FETCH_SERIES_ARTWORK),
      mergeMap((action) =>
        this.artworkService.fetchArtworkBySeries(action['seriesId']).pipe(
          map((pieces) => ({
            type: artworkActionTypes.SERIES_ARTWORK_RECEIVED,
            pieces: pieces.sort((a, b) => a.orderIndex - b.orderIndex),
            seriesId: action['seriesId']
          })),
          catchError(() => [fetchingSeriesArtworkFailedAction()])
        )
      )
    )
  );

  fetchAllArtwork$ = createEffect(() =>
    this.actions$.pipe(
      ofType(artworkActionTypes.FETCH_ALL_ARTWORK),
      mergeMap(() =>
        this.artworkService.fetchAllArtwork().pipe(
          map((pieces) => ({
            type: artworkActionTypes.ALL_ARTWORK_RECEIVED,
            pieces: pieces.sort((a, b) => a.orderIndex - b.orderIndex)
          })),
          catchError(() => [fetchingAllArtworkFailedAction()])
        )
      )
    )
  );

  uploadImages$ = createEffect(() =>
    this.actions$.pipe(
      ofType(artworkActionTypes.UPLOAD_IMAGES),
      mergeMap((action) =>
        this.artworkService.uploadImages(action['files'], action['slug']).pipe(
          map((pictures) => ({
            type: artworkActionTypes.IMAGES_UPLOADED,
            pictures,
          })),
          catchError(() => [imageUploadFailedAction()])
        )
      )
    )
  );

  upsertArtwork$ = createEffect(() =>
    this.actions$.pipe(
      ofType(artworkActionTypes.UPSERT_ARTWORK),
      mergeMap((action) => {
            return this.artworkService.upsert(action['pieces']).pipe(
          map((pieces) => ({
            type: artworkActionTypes.ARTWORK_UPSERTED,
            pieces,
          })),
          catchError(() => [artworkUpsertionFailedAction()])
        )
      })
    )
  );

  deleteArtwork$ = createEffect(() =>
    this.actions$.pipe(
      ofType(artworkActionTypes.DELETE_ARTWORK),
      mergeMap((action) => {
            return this.artworkService.deleteOne(action['id'], action['deleteImages'])
              .pipe(
                switchMap(() => [
                  artworkDeletedAction(),
                ]),
                catchError((err) => [artworkDeletionFailedAction()]))
      })
    )
  );

  deleteImage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(artworkActionTypes.DELETE_IMAGES),
      mergeMap((action) => {
            return this.artworkService.deleteImages(action['picture'])
              .pipe(
                map(() => imagesDeletedAction()),
                catchError((err) => [imageDeletionFailedAction()]))
      })
    )
  );

  constructor(
    private actions$: Actions,
    private artworkService: ArtworkService
  ) {}
}
